import React, {FC} from 'react';
import classes from './epicDraw.module.sass'
import Button from "../../shared/components/Button/Button";

const EpicDraw: FC = () => {

    const handleButtonClick = () => {
        window.location.href = 'https://t.me/EverGamingBot';
    };

    return (
        <div className={classes.epicDraw}>
            <div className={`${classes.subTitle} ${classes.title}`}>
                <span> КАК ПРИНЯТЬ УЧАСТИЕ </span>
                <span className={classes.titleBreak}> В ЭПИЧЕСКОМ И ПРИВЕТСВЕННОМ </span>
                <span> РОЗЫГРЫШАХ? </span>
            </div>
            <div className={`${classes.title} ${classes.pressButton}`}>
                <span> НАЖМИТЕ </span>
                <span> НА КНОПКУ </span>
            </div>
            <div className={classes.button}>
                <Button submitAction={handleButtonClick}>
                    ПРИНЯТЬ УЧАСТИЕ
                </Button>
            </div>
            <div className={`${classes.title} ${classes.text}`}>
                <span> И вот ты уже в нашем официальном </span>
                <span> telegram боте! </span>
            </div>
            <div className={`${classes.title} ${classes.text}`}>
                <span> При старте бота ты получишь свой первый </span>
                <span> счастливый билет. </span>
            </div>
        </div>
    );
};

export default EpicDraw;
