import React, {FC} from 'react';
import classes from "./whatIsEver.module.sass";
import {ReactComponent as Bag} from "../../assets/images/whatIsEver/bag.svg";
import {ReactComponent as Cup} from "../../assets/images/whatIsEver/cup.svg";
import {ReactComponent as Helmet} from "../../assets/images/whatIsEver/helmet.svg";
import {ReactComponent as Planet} from "../../assets/images/whatIsEver/planet.svg";
import Button from "../../shared/components/Button/Button";

const WhatIsEverMobileTags: FC = () => {

    const handleButtonClick = () => {
        window.location.href = 'https://app.evergaming.gg/';
    };

    return (
        <div className={classes.mobileTags}>
            <div className={classes.competeMobile}>
                <div className={classes.competeMobileImage}><Bag/></div>
                <div className={classes.competeMobileDescription}>
                    <div className={`${classes.subTitle} ${classes.title} ${classes.competeMobileText}`}>
                        <span> соревнуйся </span>
                        <span> и зарабатывай </span>
                    </div>
                    <div className={classes.competeMobileSubText}>
                        <span>Принимай участие в регулярных турнирах, получай призы даже если ты не занял первое место</span>
                    </div>
                </div>
            </div>
            <div className={classes.competeMobile}>
                <div className={classes.freeTournamentsMobileImage}><Cup/></div>
                <div className={classes.competeMobileDescription}>
                    <div className={`${classes.subTitle} ${classes.title} ${classes.freeTournamentsMobileText}`}>
                        <span> Бесплатные турниры </span>
                        <span> с реальными призами </span>
                    </div>
                    <div className={classes.competeMobileSubText}>
                        <span>Нет средств на вступительный взнос? Не беда! Ивенты с бесплатным входом помогут поднять бабла</span>
                    </div>
                </div>
            </div>
            <div className={classes.competeMobile}>
                <div className={classes.helmetMobileImage}><Helmet/></div>
                <div className={classes.competeMobileDescription}>
                    <div className={`${classes.subTitle} ${classes.title} ${classes.helmetMobileText}`}>
                        <span> Побеждай, даже </span>
                        <span> если ты не pro </span>
                    </div>
                    <div className={classes.competeMobileSubText}>
                        <span>Наши турниры подходят для всех, получай награды в ивентах среди игроков одинакового уровня</span>
                    </div>
                </div>
            </div>
            <div className={classes.competeMobile}>
                <div className={classes.planetMobileImage}><Planet/></div>
                <div className={classes.competeMobileDescription}>
                    <div className={`${classes.subTitle} ${classes.title} ${classes.planetMobileText}`}>
                        <span> Играй на evergaming.gg </span>
                        <span>  из любого места  </span>
                    </div>
                    <div className={classes.competeMobileSubText}>
                        <span>До компьютера очень далеко, а турнир уже через 30 минут? Все что нужно - твой гаджет и интернет!</span>
                    </div>
                </div>
                <Button submitAction={handleButtonClick}>
                    ИГРАТЬ
                </Button>
            </div>
        </div>
    );
};

export default WhatIsEverMobileTags;
