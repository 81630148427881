import React, {FC} from "react";
import classes from './subFooter.module.sass'


const SubFooter: FC = () => {
    return (
        <div className={classes.subFooter}>
            <a className={classes.subFooter__email} href="mailto:contact@evergaming.io">
                contact@evergaming.io
            </a>
            <div className={classes.subFooter__politics}>
                <a href="https://docs.google.com/document/d/1SeXmX6byNqSKneM4G0CZ2ZyXorB1TfyrCvSL-JxbKhk/edit">Политика
                    конфиденциальности</a>
                <a href="https://drive.google.com/drive/folders/1CG9FNM1uvyETTNvGoMciX52r9NrTYkZ8">Правила розыгрыша</a>
            </div>
            <div className={classes.subFooter__allRight}>
                EVERGAMING LLC - 2022 all right reserved
            </div>
        </div>
    );
};

export default SubFooter;