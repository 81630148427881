import React, { FC } from "react";
import classes from "./banner.module.sass";
import BannerInfo from "./BannerInfo";
import BannerVideo from "./BannerVideo";

const Banner: FC = () => {


  return (
    <div className={classes.banner}>
      <BannerInfo />
      <div className={classes.banner__imageBackground} />
      <BannerVideo />
    </div>
  );
};

export default Banner;
