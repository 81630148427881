import React, {FC} from "react";
import classes from './footer.module.sass'

import {ReactComponent as Twitter} from "../../assets/images/social/twitter.svg"
import {ReactComponent as Discord} from "../../assets/images/social/discord.svg"
import {ReactComponent as Facebook} from "../../assets/images/social/facebook.svg"
import {ReactComponent as Instagram} from "../../assets/images/social/instagram.svg"
import {ReactComponent as Telegram} from "../../assets/images/social/telegram.svg"

const FooterSocial: FC = () => {
    return (
        <div className={classes.footer__social}>
            <a href="https://t.me/EverGaming" target="_blank" rel="noreferrer" >
                <Telegram />
            </a>
            <a href="https://www.facebook.com/evergamingio" target="_blank" rel="noreferrer" >
                <Facebook />
            </a>
            <a href="https://twitter.com/evergaming_io" target="_blank" rel="noreferrer" >
                <Twitter />
            </a>
            <a href="https://www.facebook.com/evergamingio" target="_blank" rel="noreferrer" ><
                Instagram />
            </a>
            <a href="https://discord.gg/vbpH9KubA8" target="_blank" rel="noreferrer" >
                <Discord />
            </a>
        </div>
    );
};

export default FooterSocial;