import React, {FC} from 'react';
import classes from './ladderPlaceholder.module.sass';

interface ILadderPlaceholder{
    placeText: string[]
}

const LadderPlaceholder: FC<ILadderPlaceholder> = ({ placeText }) => {
    return (
        <div className={classes.ladderPlaceholder}>
            <span>{placeText[0]}</span>
            <span className={classes.ladderPlaceholder__secondary}>{placeText[1]}</span>
        </div>
    );
};

export default LadderPlaceholder;