import React, { FC } from "react";
import classes from "./banner.module.sass";
import LadderPlaceholder from "../../shared/components/LadderPlaceholder/LadderPlaceholder";
import { ReactComponent as WTFImage } from "../../assets/images/wtf.svg";
import Button from "../../shared/components/Button/Button";

const BannerInfo: FC = () => {

    const handleButtonClick = () => {
        window.location.href = 'https://t.me/EverGamingBot';
    };

    return (
        <div className={classes.banner__info}>
            <LadderPlaceholder placeText={['EVERGAMING -', 'ВЫИГРАТЬ ПРИЗ']}/>
            <WTFImage className={classes.banner__image} />
            <Button className={classes.banner__button} submitAction={handleButtonClick}>
                Забрать приз
            </Button>
        </div>
    );
};

export default BannerInfo;
