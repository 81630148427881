import React from 'react';
import LadderPlaceholder from "../../shared/components/LadderPlaceholder/LadderPlaceholder";
import Button from "../../shared/components/Button/Button";
import classes from './epicWin.module.sass'

const EpicWin = () => {

    const handleButtonClick = () => {
        window.location.href = 'https://t.me/EverGamingBot';
    };

    return (
        <div className={classes.win}>
            <LadderPlaceholder placeText={['ЭПИЧЕСКИЙ', 'РОЗЫГРЫШ']}/>
            <span className={classes.win__info}>регистрируйся сейчас, чтобы не пропустить</span>
            <Button className={classes.win__button} submitAction={handleButtonClick}>
                ЗАБРАТЬ ПРИЗ
            </Button>
            <div className={classes.win__add}>В нашем Telegram <a href="https://t.me/EverGamingBot"><u>боте</u></a></div>
        </div>
    );
};

export default EpicWin;