import React, {FC} from 'react';
import classes from './moreLoot.module.sass'

const MoreLoot: FC = () => {
    return (
        <div className={classes.moreLoot}>
            <div className={`${classes.subTitle} ${classes.title}`}>
                <span> НАВЕРНОЕ, ТЫ ХОЧЕШЬ  </span>
                <span> БОЛЬШЕ ЛУТА? </span>
            </div>

            <div className={`${classes.title} ${classes.text} ${classes.descDescription}`}>
                <span> Тогда пройди цепочку квестов в разделе </span>
                <span> «Эпический розыгрыш» и получи шанс забрать </span>
                <span> ценные призы! </span>
            </div>

            <div className={`${classes.title} ${classes.text} ${classes.mobileDescription}`}>
                <span> Тогда пройди цепочку квестов </span>
                <span> в разделе «Эпический розыгрыш» </span>
                <span> и получи шанс забрать </span>
                <span> ценные призы! </span>
            </div>
        </div>
    );
};

export default MoreLoot;
