import React, {FC, ReactNode} from 'react';
import classes from './button.module.sass';

interface IButton {
    children?: ReactNode,
    className?: string,
    submitAction?: (event: React.MouseEvent<HTMLElement>) => void,
}

const Button: FC<IButton> = ({ children, className = '', submitAction }) => {
    return (
        <button  className={`${classes.button} ${className}`} onClick={submitAction}>
            <span className={classes.button__content}>{children}</span>
        </button>
    );
};

export default Button;