import React, {FC} from "react";
import classes from './header.module.sass'
import {ReactComponent as EvergamingLogo} from "../../assets/images/evergaming_logo.svg"

const Header: FC = () => {
    return (
        <header className={classes.wrapper}>
            <div className={classes.logo}>
                <EvergamingLogo />
            </div>
        </header>
    );
};

export default Header;