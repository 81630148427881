/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef } from "react";
import classes from "./banner.module.sass";
import landingVideo from "../../assets/video/landing.mp4";

const BannerVideo: FC = () => {
  const volumeButton = useRef<HTMLButtonElement | null>(null);
  const videoElement = useRef<HTMLVideoElement>(null);
  let volumeIcons: NodeListOf<SVGUseElement>;
  let volumeMute: Element | null;
  let volumeHigh: Element | null;

  const updateVolumeIcon = () => {
    if (volumeIcons) {
      volumeIcons.forEach((icon) => {
        icon.classList.add(classes.banner__hidden);
      });
    }
    if (videoElement.current) {
      if (videoElement.current.muted || videoElement.current.volume === 0) {
        if (volumeMute) {
          volumeMute.classList.remove(classes.banner__hidden);
        }
      } else {
        if (volumeHigh) {
          volumeHigh.classList.remove(classes.banner__hidden);
        }
      }
    }
  };

  const toggleMute = () => {
    if (videoElement.current) {
      videoElement.current.muted = !videoElement.current.muted;
    }
  };

  useEffect(() => {
    if (volumeButton.current) {
      volumeIcons = volumeButton.current.querySelectorAll("use");
      volumeMute = volumeButton.current.querySelector(
        'use[href="#volume-mute"]'
      );
      volumeHigh = volumeButton.current.querySelector(
        'use[href="#volume-high"]'
      );
    }

    if (volumeButton.current && videoElement.current) {
      volumeButton.current.addEventListener("click", toggleMute);
      videoElement.current.addEventListener("volumechange", updateVolumeIcon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <video
        autoPlay
        loop
        muted
        ref={videoElement}
        className={classes.banner__video}
      >
        <source src={landingVideo} type="video/mp4" />
      </video>
      <div className={classes.banner__videoControls} id="video-controls">
        <div className={classes.banner__volumeControls}>
          <button className={classes.banner__volumeButton} ref={volumeButton}>
            <svg className={classes.banner__svg}>
              <use href="#volume-mute"></use>
              <use className={classes.banner__hidden} href="#volume-high"></use>
            </svg>
          </button>
        </div>
      </div>
      <svg style={{ display: "none" }}>
        <defs>
          <symbol id="volume-high" viewBox="0 0 24 24">
            <path d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"></path>
          </symbol>

          <symbol id="volume-mute" viewBox="0 0 24 24">
            <path d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"></path>
          </symbol>
        </defs>
      </svg>
    </>
  );
};

export default BannerVideo;
