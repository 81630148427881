import React, {FC} from "react";
import classes from './footer.module.sass'
import {ReactComponent as EvergamingLogo} from "../../assets/images/evergaming_logo.svg"
import FooterSocial from "./FooterSocial";
import SubFooter from "../SubFooter/SubFooter";


const Footer: FC = () => {
    return (
        <footer className={classes.footer}>
            <FooterSocial />
            <div className={classes.footer__logo}>
                <EvergamingLogo />
            </div>
            <SubFooter/>
        </footer>
    );
};

export default Footer;