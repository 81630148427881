import React from 'react';
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import EpicRoulette from "./components/EpicRoulette/EpicRoulette";
import GreetingsRoulette from "./components/GreetingsRoulette/GreetingsRoulette";
import EpicWin from "./components/EpicWin/EpicWin";
import Separator from "./components/Separator/Separator";
import WhatIsEver from "./components/WhatIsEver/WhatIsEver";
import Footer from "./components/Footer/Footer";
import EpicDraw from "./components/EpicDraw/EpicDraw";
import MoreLoot from "./components/MoreLoot/MoreLoot";

const App = () => {
    return (
        <>
            <Header/>
            <Banner/>
            <GreetingsRoulette/>
            <EpicRoulette/>
            <EpicWin/>
            <Separator/>
            <WhatIsEver/>
            <EpicDraw/>
            <MoreLoot/>
            {/*<Team/>*/}
            {/*<Form/>*/}
            <Footer/>
        </>
    );
}

export default App;
