import React, {FC} from 'react';
import classes from './whatIsEver.module.sass'
import {ReactComponent as Bag} from "../../assets/images/whatIsEver/bag.svg";
import {ReactComponent as Cup} from "../../assets/images/whatIsEver/cup.svg";
import {ReactComponent as Helmet} from "../../assets/images/whatIsEver/helmet.svg";
import {ReactComponent as Planet} from "../../assets/images/whatIsEver/planet.svg";
import WhatIsEverMobileTags from "./WhatIsEverMobileTags";
import Button from "../../shared/components/Button/Button";

const WhatIsEver: FC = () => {

    const handleButtonClick = () => {
        window.location.href = 'https://app.evergaming.gg/';
    };

    return (

        <div className={classes.whatIsEver}>

            <div className={`${classes.whatTitle} ${classes.title}`}>
                <span> ЧТО ТАКОЕ </span>
                <span> EVERGAMING </span>
            </div>

            <div className={`${classes.subTitle} ${classes.title}`}>
                <span> Регулярные турниры </span>
                <span> по мобильным играм </span>
            </div>

            <div className={classes.background}>
                <div className={classes.tags}>
                    <div className={`${classes.tag} ${classes.title}`}>
                        <span> Проведено </span>
                        <span> 2000+ </span>
                        <span> Турниров </span>
                    </div>

                    <div className={classes.prizeTag}>
                        <span className={classes.pay}> Выплачено </span>
                        <div className={classes.billion}>
                            <div className={classes.bil1}>
                                <span> {'$'}50000 </span>
                            </div>
                            <div className={classes.bilEver}>
                                <span>  </span>
                                <span>  </span>
                            </div>
                        </div>
                        <span className={classes.prize}> призовых </span>
                    </div>

                    <div className={`${classes.tag} ${classes.title}`}>
                        <span> Более </span>
                        <span> 35000 </span>
                        <span> Участников </span>
                    </div>
                </div>
                <div className={classes.desktopTags}>
                    <div className={classes.compete}>
                        <div className={classes.bag}><Bag/></div>
                        <div className={classes.description}>
                            <div className={classes.competeLine}>
                                <div className={`${classes.subTitle} ${classes.title} ${classes.competeText}`}>
                                    <span> ИГРАЙ НА <a href="https://app.evergaming.gg/">EVERGAMING</a>  </span>
                                    <span> ИЗ ЛЮБОГО МЕСТА </span>
                                </div>
                            </div>
                            <div className={classes.competeSubText}>
                                <span>До компьютера очень далеко, а турнир уже через 30 минут? Все что нужно - твой гаджет и интернет!</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.freeTournaments}>
                        <div className={classes.freeTournamentsDescription}>
                            <div className={classes.freeTournamentsLine}>
                                <div className={`${classes.subTitle} ${classes.title} ${classes.freeTournamentsText}`}>
                                    <span> Бесплатные турниры </span>
                                    <span> с реальными призами </span>
                                </div>
                            </div>
                            <div className={classes.freeTournamentsTextSubText}>
                                <span>Нет средств на вступительный взнос? Не беда! Ивенты с бесплатным входом помогут поднять бабла</span>
                            </div>
                        </div>
                        <div className={classes.cup}><Cup/></div>
                    </div>

                    <div className={classes.compete}>
                        <div className={classes.helmet}><Helmet/></div>
                        <div className={classes.description}>
                            <div className={classes.competeLine}>
                                <div className={`${classes.subTitle} ${classes.title} ${classes.winText}`}>
                                    <span> Побеждай, даже </span>
                                    <span> Если ты не pro </span>
                                </div>
                            </div>
                            <div className={classes.winSubText}>
                                <span>Наши турниры подходят для всех, получай награды в ивентах среди игроков одинакового уровня</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.freeTournaments}>
                        <div className={classes.freeTournamentsDescription}>
                            <div className={classes.freeTournamentsLine}>
                                <div className={`${classes.subTitle} ${classes.title} ${classes.playText}`}>
                                    <span> Играй на evergaming.gg </span>
                                    <span> из любого места </span>
                                </div>
                            </div>
                            <div className={classes.freeTournamentsTextSubText}>
                                <span>До компьютера очень далеко, а турнир уже через 30 минут? Все что нужно - твой гаджет и интернет!</span>
                            </div>
                        </div>
                        <div className={classes.planet}><Planet/></div>
                    </div>
                    <div className={classes.play_button}>
                        <Button submitAction={handleButtonClick}>
                            ИГРАТЬ
                        </Button>
                    </div>

                </div>
                <WhatIsEverMobileTags/>
            </div>
        </div>
    );
};

export default WhatIsEver;
