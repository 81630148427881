import React, {FC} from 'react';
import classes from './greetingsRoulette.module.sass';
import greeting from '../../assets/images/greetingsRoulette/greetingLoot.png'

const GreetingsRoulette: FC = () => {
    return (
        <div className={classes.roulette}>
            <a href={"https://app.evergaming.gg/"}>
                <div className={classes.roulette__image}>
                    <img alt={'loot'} className={classes.roulette__main} src={greeting}/>
                </div>
            </a>
        </div>
    );
};

export default GreetingsRoulette;
