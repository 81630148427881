import React from 'react';
import classes from "./epicRoulette.module.sass";
import main from "../../assets/images/epicRoulette/epicLoot.png";

const EpicRoulette = () => {
    return (
        <div className={classes.roulette}>
            <a href={"https://app.evergaming.gg/"}>
                <div className={classes.roulette__image}>
                    <img className={classes.roulette__main} src={main}/>
                </div>
            </a>
            <div className={classes.roulette__info}>
                * полный список призов доступен для ознакомления в <a href="https://t.me/EverGamingBot"><u>боте</u></a>
            </div>
        </div>
    );
};

export default EpicRoulette;
