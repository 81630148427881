import React, {FC} from 'react';
import classes from './separator.module.sass'
import {ReactComponent as EvergamingLogo} from "../../assets/images/logo.svg"

const Separator: FC = () => {
    return (
        <div className={classes.separator}>
            <div className={classes.line}>

            </div>
            <div className={classes.logo}>
                <EvergamingLogo/>
            </div>
            <div className={classes.line}>

            </div>
        </div>
    );
};

export default Separator;
